import "./styles.scss"

import React, { useEffect, useRef, useState } from "react"
import InputRange from "react-input-range"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal"

import { Switch, DollyHousePopup } from "../components"

import IcoArrow from "assets/icons/ico-apartments-arrow.svg"
import IcoEnvelope from "assets/icons/ico-envelope.svg"
import IcoView from "assets/icons/ico-view.svg"

const Listing = ({ apartmentList, view, setView }) => {
  const [dollyHousePopup, setDollyHousePopup] = useState(false)
  const [dollyHouseImage, setDollyHouseImage] = useState(null)

  const listingData = {
    view,
    setView,
    dollyHousePopup,
    setDollyHousePopup,
    dollyHouseImage,
  }

  const highestArea = Math.ceil(
      Math.max(...apartmentList.map(item => item.acfApartment.area))
    ),
    lowestArea = Math.floor(
      Math.min(...apartmentList.map(item => item.acfApartment.area))
    )

  const [areaRange, setAreaRange] = useState({
    value: { min: lowestArea, max: highestArea },
  })
  const [apartmentFilter, setApartmentFilter] = useState({
    areaStart: lowestArea,
    areaEnd: highestArea,
    buildings: [],
    rooms: [],
    floors: [],
    status: [],
  })

  let filteredApartments = apartmentList

  const setRange = value => {
    setAreaRange({ value })
    setApartmentFilter({
      ...apartmentFilter,
      areaStart: value.min,
      areaEnd: value.max,
    })
  }

  const setFloor = floorNumber => {
    if (apartmentFilter.floors.includes(floorNumber)) {
      let floors = apartmentFilter.floors
      const indexOfItem = apartmentFilter.floors.indexOf(floorNumber)

      floors.splice(indexOfItem, 1)
      setApartmentFilter({ ...apartmentFilter, floor: floors })
    } else {
      setApartmentFilter({
        ...apartmentFilter,
        floors: [...apartmentFilter.floors, floorNumber],
      })
    }
  }

  const setRooms = roomNumber => {
    if (apartmentFilter.rooms.includes(roomNumber)) {
      let rooms = apartmentFilter.rooms
      const indexOfItem = apartmentFilter.rooms.indexOf(roomNumber)

      rooms.splice(indexOfItem, 1)
      setApartmentFilter({ ...apartmentFilter, rooms: rooms })
    } else {
      setApartmentFilter({
        ...apartmentFilter,
        rooms: [...apartmentFilter.rooms, roomNumber],
      })
    }
  }

  const setStatus = status => {
    if (apartmentFilter.status.includes(status)) {
      let statusList = apartmentFilter.status
      const indexOfItem = apartmentFilter.status.indexOf(status)

      statusList.splice(indexOfItem, 1)
      setApartmentFilter({ ...apartmentFilter, status: statusList })
    } else {
      setApartmentFilter({
        ...apartmentFilter,
        status: [...apartmentFilter.status, status],
      })
    }
  }

  const apartmentsFiletering = () => {
    filteredApartments = apartmentList.filter(item => {
      const floor = parseInt(
          item.acfApartment.floor ? item.acfApartment.floor : 0
        ),
        room = parseInt(item.acfApartment.roomsnumber)

      if (
        item.acfApartment.area >= apartmentFilter.areaStart &&
        item.acfApartment.area <= apartmentFilter.areaEnd &&
        (apartmentFilter.floors.includes(floor) ||
          apartmentFilter.floors.length === 0) &&
        (apartmentFilter.rooms.includes(room) ||
          apartmentFilter.rooms.length === 0) &&
        (apartmentFilter.buildings.includes(item.acfApartment.buildingNumber) ||
          apartmentFilter.buildings.length === 0) &&
        (apartmentFilter.status.includes(item.acfApartment.status) ||
          apartmentFilter.status.length === 0)
      ) {
        return item
      }
      return false
    })
  }

  const isBrowser = typeof window !== "undefined"

  const saveFilters = () => {
    const json = JSON.stringify(apartmentFilter)

    localStorage?.setItem("saved-filters", json)
  }

  useEffect(() => {
    if (isBrowser) {
      const json = localStorage?.getItem("saved-filters")

      if (json) {
        const filters = JSON.parse(json)

        setApartmentFilter(filters)
        setAreaRange({
          value: { min: filters.areaStart, max: filters.areaEnd },
        })
      }
    }
  }, [])

  const [isInit, setIsInit] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsInit(true)
    }, 0)
  }, [])

  const disableTransition = !isInit
    ? {
        style: { transition: "none" },
      }
    : undefined

  return (
    <section className="apartments-listing">
      <div className="apartments-listing__filtering">
        <div className="apartments-listing__filtering-wrapper">
          <div className="row align-items-center flex-column-reverse flex-xl-row">
            <div className="col-xl-6 offset-xl-3 text-center">
              <h2>Lista mieszkań</h2>
            </div>
            <div className="col-xl-3">
              <Switch {...listingData} />
            </div>
          </div>
          {apartmentsFiletering()}
          <div className="row row--filters">
            <div className="col-lg">
              <h4>Metraż</h4>
              <InputRange
                allowSameValues={false}
                maxValue={highestArea}
                minValue={lowestArea}
                value={areaRange.value}
                onChange={value => setRange(value)}
              />
              <div className="input-range__legend">
                <p>
                  {areaRange.value.min}m<sup>2</sup>
                </p>
                <p>
                  {areaRange.value.max}m<sup>2</sup>
                </p>
              </div>
            </div>
            <div className="col-lg">
              <h4>Ilość pokoi</h4>
              <div className="apartments-listing__filtering-row">
                <button
                  onClick={() => setRooms(1)}
                  className={apartmentFilter.rooms.includes(1) ? "current" : ""}
                  {...disableTransition}
                >
                  1
                </button>
                <button
                  onClick={() => setRooms(2)}
                  className={apartmentFilter.rooms.includes(2) ? "current" : ""}
                  {...disableTransition}
                >
                  2
                </button>
                <button
                  onClick={() => setRooms(3)}
                  className={apartmentFilter.rooms.includes(3) ? "current" : ""}
                  {...disableTransition}
                >
                  3
                </button>
                <button
                  onClick={() => setRooms(4)}
                  className={apartmentFilter.rooms.includes(4) ? "current" : ""}
                  {...disableTransition}
                >
                  4
                </button>
              </div>
            </div>
            <div className="col-lg">
              <h4>Piętro</h4>
              <div className="apartments-listing__filtering-row">
                <button
                  onClick={() => setFloor(0)}
                  className={
                    apartmentFilter.floors.includes(0) ? "current" : ""
                  }
                  {...disableTransition}
                >
                  0
                </button>
                <button
                  onClick={() => setFloor(1)}
                  className={
                    apartmentFilter.floors.includes(1) ? "current" : ""
                  }
                  {...disableTransition}
                >
                  1
                </button>
                <button
                  onClick={() => setFloor(2)}
                  className={
                    apartmentFilter.floors.includes(2) ? "current" : ""
                  }
                  {...disableTransition}
                >
                  2
                </button>
                <button
                  onClick={() => setFloor(3)}
                  className={
                    apartmentFilter.floors.includes(3) ? "current" : ""
                  }
                  {...disableTransition}
                >
                  3
                </button>
                <button
                  onClick={() => setFloor(4)}
                  className={
                    apartmentFilter.floors.includes(4) ? "current" : ""
                  }
                  {...disableTransition}
                >
                  4
                </button>
                <button
                  onClick={() => setFloor(5)}
                  className={
                    apartmentFilter.floors.includes(5) ? "current" : ""
                  }
                  {...disableTransition}
                >
                  5
                </button>
              </div>
            </div>
            <div className="col-lg-2">
              <h4>Status</h4>
              <div className="apartments-listing__filtering-row">
                <button
                  onClick={() => setStatus("available")}
                  className={`available${
                    apartmentFilter.status.includes("available")
                      ? " current"
                      : ""
                  } `}
                  {...disableTransition}
                >
                  W
                </button>
                <button
                  onClick={() => setStatus("reserved")}
                  className={`reserved${
                    apartmentFilter.status.includes("reserved")
                      ? " current"
                      : ""
                  } `}
                  {...disableTransition}
                >
                  Z
                </button>
                <button
                  onClick={() => setStatus("sold")}
                  className={`sold${
                    apartmentFilter.status.includes("sold") ? " current" : ""
                  } `}
                  {...disableTransition}
                >
                  S
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="apartments-listing__results">
          <div className="apartments-listing__results-header">
            <div className="row">
              <div className="col">Nr lokalu</div>
              <div className="col">Pokoje</div>
              <div className="col">Metraż</div>
              <div className="col">Piętro</div>
              <div className="col">Ekspozycja</div>
              {/* <div className="col">
                Cena za m<sup>2</sup>
              </div>
              <div className="col">Cena</div> */}
              <div className="col">Status</div>
              <div className="col">Rzut</div>
              <div className="col">Kontakt</div>
              <div className="col"></div>
            </div>
          </div>

          {filteredApartments.length !== 0 ? (
            filteredApartments
              .sort(function (a, b) {
                return a.acfApartment.apartmentNumber.localeCompare(
                  b.acfApartment.apartmentNumber,
                  undefined,
                  {
                    numeric: true,
                    sensitivity: "base",
                  }
                )
              })
              .map((item, index) => {
                return (
                  <Fade cascade triggerOnce key={index}>
                    <div className="apartments-listing__results-item">
                      <div className="row align-items-center" key={index}>
                        <div className="col">
                          {item.acfApartment.apartmentNumber}
                        </div>
                        <div className="col">
                          {item.acfApartment.roomsnumber}
                        </div>
                        <div className="col">
                          {item.acfApartment.area.toString().replace(".", ",")}m
                          <sup>2</sup>
                        </div>
                        <div className="col">
                          {item.acfApartment.floor
                            ? item.acfApartment.floor
                            : 0}
                        </div>
                        <div className="col">{item.acfApartment.location}</div>
                        {/* <div className="col">
                          {item.acfApartment.status !== "sold" && (
                            <>
                              {item.acfApartment.priceForSquareMeter.toLocaleString()}{" "}
                              zł
                            </>
                          )}
                        </div>
                        <div className="col">
                          <p className="price">
                            {item?.acfApartment?.regularPrice && (
                              <span>
                                {item?.acfApartment?.regularPrice.toLocaleString()}{" "}
                                zł
                              </span>
                            )}
                            {item.acfApartment.status !== "sold" && (
                              <>{item.acfApartment.price.toLocaleString()} zł</>
                            )}
                          </p>
                        </div> */}
                        <div className={`col ${item.acfApartment.status}`}>
                          {item.acfApartment.status === "available"
                            ? "wolne"
                            : item.acfApartment.status === "reserved"
                            ? "zarezerwowane"
                            : "sprzedane"}
                        </div>
                        <div className="col">
                          {item.acfApartment.dollhouseView && (
                            <button
                              onClick={() => {
                                setDollyHousePopup(true)
                                setDollyHouseImage(
                                  item.acfApartment.dollhouseView
                                )
                              }}
                              href={item.acfApartment.dollhouseView}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img src={IcoView} alt="" />
                            </button>
                          )}
                        </div>
                        <div className="col">
                          <Link to="/kontakt/">
                            <img src={IcoEnvelope} alt="" />
                          </Link>
                        </div>
                        <div className="col">
                          {item.acfApartment.status !== "sold" && (
                            <Link
                              to={item.uri}
                              onClick={saveFilters}
                              state={{ view: "listing" }}
                            >
                              <img src={IcoArrow} alt="" />
                            </Link>
                          )}
                        </div>
                      </div>
                      {item.acfApartment.status !== "sold" && (
                        <Link
                          className="apartments-listing__results-link"
                          to={item.uri}
                          onClick={saveFilters}
                          state={{ view: "listing" }}
                        />
                      )}
                    </div>
                  </Fade>
                )
              })
          ) : (
            <p className="apartments-listing__results-info">
              <br />
              Brak mieszkań spełniających wybrane kryteria. Zmień parametry
              wyszukiwania lub <Link to="/kontakt/">
                skontaktuj się z nami
              </Link>{" "}
              - chętnie pomożemy w dokonaniu najlepszego wyboru.
            </p>
          )}
        </div>
      </div>

      {dollyHousePopup && <DollyHousePopup {...listingData} />}
    </section>
  )
}

export default Listing
